import {Children} from 'types/base.types';

type MaybeProps = {
  condition: boolean | string | number | UndefinedOrNull;
  children: Children;
};

/**
 * Taken from the monad naming vibe. Maybe is a conditional that either returns
 * what you stipulate or not, given a conditional.
 *
 * @param {MaybeProps} props
 * @returns {React.ReactElement}
 * @constructor
 */
const Maybe = (props: MaybeProps): React.ReactElement => {
  const {condition, children} = props;

  return <>{Boolean(condition) && children}</>;
};

export default Maybe;
